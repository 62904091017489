import { useLocation } from "react-router-dom";
import './App.css';
import logo from "./images/viewslogo.png";



function Details(props) {
  const location= useLocation();
  const data = location.state?.singleproduct;
  console.log(data,"props")
  return (
    <div >
      <section id="home">
        <header>
            <a href="www.mkherbalindustry.com">
              <img src={logo} alt="logo"/>
          </a>
            <ul id="menu">
                <li><a href="/#home">Home</a></li>
                <li><a href="/#about">About Us</a></li>
                <li><a href="/#product">Product</a></li>
                {/* <li><a href="#pricing">Pricing</a></li> */}
                <li><a href="/#contact">Contact</a></li>
            </ul>
        </header>
         <div className="detail">
         <div className="left">
          <img src={data.img}/>
         </div>
         <div className="right">
          <h1>{data.name}</h1>
          <div className="detail-description">
            <p>{data? data.description : 'data aayena'}</p>
          </div>
         </div>
         </div>
    </section>
    
    
    </div>
  );
}


export default Details;
