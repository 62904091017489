import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './Home'
import Details from './Detail'





function App() {
  

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/detail" element={<Details />}/>
        </Routes>
      </Router>
    
    </div>
  );
}

export default App;
