import './App.css';
import logo from "./images/viewslogo.png";
import data from './data.json'
// import keratin from "./Images/keratin.png"
import Homeimage from "./images/home1.jpg";
import cateloge from "./images/catloge.pdf";
import {Link} from 'react-router-dom'
import { useState } from 'react';
// const [singleproduct, setSingleproduct] = useState('0000')




function Home() {
  const [singleproduct, setSingleproduct] = useState({
    pid:'',
    name:'',
    img:'',
    description:''

    })

  return (
    <div className="App">
<section id="home">
        <header>
            <a href="www.mkherbalindustry.com">
              <img src={logo} alt="logo"/>
          </a>
            <ul id="menu">
                <li><a href="#home">Home</a></li>
                <li><a href="#about">About Us</a></li>
                <li><a href="#product">Product</a></li>
                {/* <li><a href="#pricing">Pricing</a></li> */}
                <li><a href="#contact">Contact</a></li>
            </ul>
        </header>
         <img id='backgroundimg' src={Homeimage} alt=''/>
         {/* <div className='slo'>Hello</div> */}
    </section>
    <section id="about">
      <div className='companyimage'>.</div>
      <h1>Views Cosmetics is a Cosmetic Manufacture company based on respect, and research about cosmetics, Haircure and skincare products. Views Cosmetics create beauty products in a highly professional way.</h1>
      <button><a href={cateloge}>View Catloge</a></button>
      </section>
      <h1 id='ourproduct'>Our Products</h1>
    <section id="product">
    {
      data && data.map((data)=>{ 
  
        return(
         
          <div className='product' key={data.id} on onMouseOver={()=>{
            setSingleproduct({
              pid:data.id,
              name: data.pname,
              img: data.pimg,
              description: data.pdescription

            })
           
          }}>
                <h3>{data.pname}</h3>
                <div className='productimage'>
                <img src={ data.pimg } alt={data.id}/>
                </div>
                <div className='aboutProduct'>
                  {/* <li>{data.pdescription}</li> */}
                  <li>
                  <button>
                  <Link to='/detail' 
                   
                  state={{singleproduct : singleproduct}} >
                      View Details
                  </Link>
                  </button>
                  </li>
                            
                </div>
              </div>
              
        )
      })
    }

      
    </section>
    {/* <section id="pricing"><h1>This Is Pricing</h1></section> */}
    <section id="contact">
   
      <div className='contact'>
      <img src={logo} alt=''/>
        <h1>MK Herbal Industry Pvt. Ltd.</h1>
      <span>Lalitpur Nepal</span>
      </div>
      
    </section>
    
    </div>
  );
}

export default Home;
